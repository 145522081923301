import styled, { css } from 'styled-components';
import { CreateEventButton } from '../MainEvents/styles';
const MediumFont = 'Rubik-Medium';
const Layout = styled.div`
  width: 100%;
  display: flex;
  .float {
    width; 100%;
    float; left;
  }
>div:first-child{
  width: 67.1%;
}
> div: last-child {
  width: 32.9%;
  padding-left: 25px;
}
  @media (max-width: 500px) {
    padding: 0 10px 30px;
  }
`;

const Title = styled.p`
  font-size: 12px;
  color: ${({ color }) => color ? color : '#159fc9'};
  margin-bottom: 0;
  text-transform: ${({ mainTitle }) => mainTitle ? 'uppercase' : 'none'};
  line-height: 20px;
  text-align: ${({ center }) => center && 'center'};
`;

const AdventureTitle = styled.div`
  font-size: 18px;
  letter-spacing: 0px;
  color: rgb(51,51,51);
  text-align: left;
  text-align: ${({ center }) => center && 'center'};
`;

const ArticleSection = styled.div`
  padding: 0px 0px 25px 0px;
  float: left;
  width: 100%;

  @media (max-width: 500px) {
    padding: 10px 10px 45px;
    margin-top: 10px;
  }
`;

const Description = styled.div`
  padding: ${({ padding }) => padding ? '0px' : '0 15px'};
  text-align: ${({ textAlign }) => textAlign ? 'left' : 'justify'};
  word-break: break-word;
  font-family: Rubik;
  p{
    strong{
     font-family: ${MediumFont};
   }
  }
  table{
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    overflow-x: auto;
    overflow-y: hidden;
    white-space: nowrap;
  }
  ${'' /* ol{
    list-style-position: inside;
    list-style-type: decimal;
  }
  ul{
    list-style-position: inside;
    list-style-type: dot;
  } */}
`;

const Button = styled.button`
  background-color: rgb(246, 121, 125);
  border: 1px solid rgb(246, 121, 125);
  border: ${({ color }) => color ? '1px solid #9FC989' : '1px solid rgb(246, 121, 125)'};
  color: white;
  white-space: nowrap;
  padding: 11px 110px;
  font-size: 18px;
  line-height: 24px;
  line-height: 1.428571429;
  border-radius: 3px;
  font-family: Rubik-Medium;
  width: auto;
  display: flex;
  background-color: ${({ color }) => color ? color : 'rgb(246, 121, 125)'};
  ${({ disabled }) => disabled === true && css`
  opacity: 0.5;
  cursor: not-allowed !important;
`}
  >img{
    display: flex;
    margin: auto;
    width: 23px;
    height: 23px;
    margin-right: 10px;
  }

  > i {
    color: white;
    margin-left: 12px;
  }

  &:hover, &:active, &:focus {
    outline: none;
  }
`;

const ButtonContainer = styled.div`
  width: 100%;
  text-align: right;
  margin-top: 35px;
  float: left;
  display: flex;
`;

const ImageContainer = styled.div`
  width: 100%;
  height: 524px;
  > div {
    > img {
      width: 100% !important;
      height: 100% !important;
      border-radius: 6px;
    }
  }
  @media (max-width: 767px) and (min-width: 501px) {
    height: 300px;
  }

  @media (max-width: 500px) {
    height: 250px;
  }
`;

const TitleContainer = styled.div`
  display: inline-block;
  float: ${({ float }) => float ? '' : 'left'};
  width: 100%;
  >div:first-child{
    display: flex;
    background: linear-gradient(rgb(82, 168, 237) 0%, rgb(138, 211, 247) 100%);
    border-radius: 6px;
    height: 50px;
    >div{
      width: 50px;
      background: white;
      display: flex;
      height: 50px;
      border-radius: 6px;
    >img{
       width: 32px;
       height: 32px;
       display: flex;
       margin: auto;
    }}
    >span{
      display: flex;
      margin: auto;
      margin-left: 10px;
      text-transform: capitalize;
    font-family: Rubik-Medium;
    font-weight: 500;
    font-size: 18px;
    line-height: 24px;
    color: white;
    }
  }
  >div:last-child{
    display: flex;
    padding: 15px 0px;
    >div:first-child{
      width: 80%;
      text-transform: capitalize;
    font-family: Rubik-Medium;
    font-weight: 500;
    font-size: 20px;
    color: #0D4270;
    }
    >div:last-child{
      width: 20%;
      display: flex;
      justify-content: flex-end;
      >img{
        width:20px;
        height: 30px;
      }
    }
  }

  @media (max-width: 500px) {
    margin-bottom: 10px;
  }
`;

const EditButton = styled(CreateEventButton)`
  font-size: 12px;
  padding: 0 20px;
  height: 29px;
  margin-left: 10px;

  @media(max-width: 900px) {
    margin-top: ${({ marginTop }) => marginTop && marginTop};
  }
  
  ${({ createButton }) => createButton && css`
    margin: 20px 25px 0 0;
    
    @media (max-width: 500px) {
      margin: 0 auto 20px;
      float: unset;
      display: flex;
    }
  `}
`;

const Float = styled.div`
  width: 100%;
  float: left;
`;

const ImageContainerIcons = styled.div`
  width: 100%;
  margin-bottom: 25px;
  margin-bottom: ${({ margin }) => margin ? '0px' : '25px'};
  display: flex;
  padding: ${({ padding }) => padding ? '15px' : '0 15px'};
  background: white;
  border-radius: 0 0 6px 6px;
  height: 60px;
  >div:first-child{
    width: 50%;
    display: flex;
    >div:first-child{
      display: flex;
      justify-content: flex-start;
      margin-right: 15px;
      >img{
        height: 30px;
        width: auto;
        margin: auto;
      }
      >span{
        font-size: 16px;
        font-family: rubik;
        margin: auto;
        margin-left: 15px;
        text-align: center;
        font-family: Rubik;
font-style: normal;
font-weight: normal;
font-size: 14px;
line-height: 12px;
color: #3A3A3A;
      }
    }    
    >div:nth-child(2){
      display: flex;
      margin-right: 15px;
      >img{
        width: auto;
        height: 30px;
        margin: auto;
      }
      >span{
        font-size: 16px;
        font-family: rubik;
        text-align: center;
        margin: auto;
        margin-left: 15px;
        font-family: Rubik;
        font-style: normal;
        font-weight: normal;
        font-size: 14px;
        line-height: 12px;
        color: #3A3A3A;

      }
    }
    >div:last-child{
      display: flex;
      >img{
        width: auto;
        height: 30px;
        margin: auto;
      }
      >span{
        font-size: 14px;
        font-family: rubik;
        text-align: center;
        margin: auto;
        margin-left: 15px;
        font-family: Rubik;
        font-style: normal;
        font-weight: normal;
        font-size: 14px;
        line-height: 12px;
        color: #3A3A3A;

      }
    }

  }
  > div: last-child {
    width: 50%;
    display: flex;
    justify-content: flex-end;
    display: flex;
    margin: auto;
  }
`;

const AdminButtons = styled.div`
  width: 100%;
  display: flex;
  height: auto;
  background: ${({ background }) => background ? background : 'green'};
  margin-bottom: 25px;
  cursor: pointer;
  border-radius: 3px;
  padding: 10px 0px;
  >div:first-child{
    width: 15%;
    display: flex;
    >img{
      height: 32px;
      width: auto;
      display: flex;
      margin: auto;
      margin-left: 10px;
    }
  }
  > div: last-child {
    width: 85%;
    display: flex;
    justify-content: center;
    font-family: Rubik-Medium;
    font-weight: 500;
    font-size: 18px;
    line-height: 24px;
    color: #FFFFFF;
    margin: auto;
    padding-right: 35px;
  }
`;

const CircularBar = styled.div`


.circle-background,
.circle-progress {
  fill: none;
}

.circle-background {
  stroke: #ddd;
}

.circle-progress {
  stroke: #fd7175;
  stroke-linecap: round;
  stroke-linejoin: round;
}

.circle-text {
  font-size: 3em;
  font-weight: bold;
  fill: black;
}

.points-earned{
  color: white;
  font-size: 16px;
  font-family-rubik;
  fill: white;
}
`;


export {
  Float, Layout, Title, AdventureTitle, ArticleSection, Description, Button, ButtonContainer, ImageContainer, TitleContainer,
  EditButton, ImageContainerIcons, AdminButtons, CircularBar
};
